import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { Link, type LinkProps } from 'dibs-elements/exports/Link';

type Props = {
    wrapperClass?: string;
    linkClass?: string;
    linkType?: LinkProps['buyerLinkType'];
    trackClickEvent?: (args: { label: string }) => void;
};

const RecaptchaTerms: FC<Props> = ({ wrapperClass, linkClass, linkType, trackClickEvent }) => {
    return (
        <div className={wrapperClass}>
            <FormattedMessage
                id="recaptcha.recaptchaTerms"
                defaultMessage="This site is protected by reCAPTCHA and the Google <privacy>Privacy Policy</privacy> and <terms>Terms of Service</terms> apply."
                values={{
                    privacy: chunks => (
                        <Link
                            href="https://policies.google.com/privacy"
                            target="_blank"
                            underline="underline"
                            className={linkClass}
                            buyerLinkType={linkType}
                            onClick={() => {
                                if (typeof trackClickEvent === 'function') {
                                    trackClickEvent({ label: 'Privacy Policy' });
                                }
                            }}
                        >
                            {chunks}
                        </Link>
                    ),
                    terms: chunks => (
                        <Link
                            href="https://policies.google.com/terms"
                            target="_blank"
                            underline="underline"
                            className={linkClass}
                            buyerLinkType={linkType}
                            onClick={() => {
                                if (typeof trackClickEvent === 'function') {
                                    trackClickEvent({ label: 'Terms of Service' });
                                }
                            }}
                        >
                            {chunks}
                        </Link>
                    ),
                }}
            />
        </div>
    );
};

export default RecaptchaTerms;
