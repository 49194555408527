import { type FC } from 'react';
import LinkColumnItem from '../LinkColumn/LinkColumnItem';

import SocialLinkRow from '../SocialLinkRow/SocialLinkRow';
import { type SocialLinkRowItemProps } from '../SocialLinkRow/SocialLinkRowItem';
import { connect } from 'react-redux';
import { blog, magazine } from '../messages/connectLinks';
import classnames from 'classnames';

import styles from './ConnectRow.scss';

type Props = {
    items: SocialLinkRowItemProps[];
    isClient: boolean;
    isMobile: boolean;
    viewer?: $TSFixMe;
};

export const ConnectRowComponent: FC<Props> = ({ isMobile, items, viewer }) => {
    return (
        <li className={classnames(styles.container, { [styles.isMobile]: isMobile })}>
            <ul>
                {magazine.enablerKey && viewer[magazine.enablerKey] === false ? null : (
                    <LinkColumnItem key={'footer-magazine'} isConnectLink {...magazine} />
                )}
                {blog.enablerKey && viewer[blog.enablerKey] === false ? null : (
                    <LinkColumnItem key={'footer-blog'} isConnectLink {...blog} />
                )}
            </ul>
            <SocialLinkRow items={items} />
        </li>
    );
};

const mapStateToProps = ({ header }: { header: { isMobile: boolean } }): { isMobile: boolean } => ({
    isMobile: header.isMobile,
});

export const ConnectRow = connect(mapStateToProps)(ConnectRowComponent);
