import { FormattedMessage } from 'dibs-react-intl';
import { type FooterLink } from '../FooterLinkTypes';

const sellerLinks: FooterLink[] = [
    {
        trackingLabel: 'Dealer Application',
        href: '/dealer/application-form/',
        dataTn: 'footer-dealer-application',
        message: (
            <FormattedMessage
                id="dbl.Footer.primary_links.dealer_application"
                defaultMessage="Seller Application"
            />
        ),
    },
    {
        trackingLabel: 'NFT Creator Application',
        href: '/info/nft-creator-application/',
        dataTn: 'footer-nft-creator-application',
        enablerKey: 'footerNftLink',
        message: (
            <FormattedMessage
                id="dbl.Footer.primary_links.nft_creator_application"
                defaultMessage="NFT Creator Application"
            />
        ),
    },
    {
        trackingLabel: 'Dealer Login',
        href: 'https://adminv2.1stdibs.com/login/dealer/',
        dataTn: 'footer-dealer-login',
        message: (
            <FormattedMessage
                id="dbl.Footer.primary_links_dealer_login"
                defaultMessage="Seller Login"
            />
        ),
    },
    {
        trackingLabel: 'Seller Directory',
        href: '/seller-directory/',
        dataTn: 'footer-seller-directory',
        message: (
            <FormattedMessage
                id="dbl.Footer.primaryLinksSellerDirectory"
                defaultMessage="Seller Directory"
            />
        ),
    },
];

export default sellerLinks;
