import { FormattedMessage } from 'dibs-react-intl';
import { type FooterLink } from '../FooterLinkTypes';

const sitemapLinks: FooterLink[] = [
    {
        trackingLabel: 'Furniture',
        href: '/sitemap/furniture/',
        dataTn: 'sitemap-furniture',
        enablerKey: 'sitemapLinks',
        message: (
            <FormattedMessage id="dbl.Footer.sitemap_links_furniture" defaultMessage="Furniture" />
        ),
    },
    {
        trackingLabel: 'Jewelry & Watches',
        href: '/sitemap/jewelry/',
        dataTn: 'sitemap-jewelry',
        enablerKey: 'sitemapLinks',
        message: (
            <FormattedMessage
                id="dbl.Footer.sitemap_links_jewelry"
                defaultMessage="Jewelry & Watches"
            />
        ),
    },
    {
        trackingLabel: 'Art',
        href: '/sitemap/art/',
        dataTn: 'sitemap-art',
        enablerKey: 'sitemapLinks',
        message: <FormattedMessage id="dbl.Footer.sitemap_links_art" defaultMessage="Fine Art" />,
    },
    {
        trackingLabel: 'Fashion',
        href: '/sitemap/fashion/',
        dataTn: 'sitemap-fashion',
        enablerKey: 'sitemapLinks',
        message: (
            <FormattedMessage id="dbl.Footer.sitemap_links_fashion" defaultMessage="Fashion" />
        ),
    },
    {
        trackingLabel: 'Creators',
        href: '/creators/furniture/',
        dataTn: 'creators',
        message: (
            <FormattedMessage id="dbl.Footer.sitemap_links_creators" defaultMessage="Creators" />
        ),
    },
    {
        trackingLabel: 'Questions & Answers',
        href: '/answers/',
        dataTn: 'footer-qa',
        enablerKey: 'footerQuestionsAnswersLink',
        message: (
            <FormattedMessage id="dbl.Footer.siteLinksQa" defaultMessage="Questions & Answers" />
        ),
    },
    {
        trackingLabel: 'NFTs',
        href: '/nft-collections/',
        dataTn: 'footer-nft',
        enablerKey: 'footerNftLink',
        message: <FormattedMessage id="dbl.Footer.siteLinksNFT" defaultMessage="NFTs" />,
    },
];

export default sitemapLinks;
