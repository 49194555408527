import { type FC, type ReactNode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import FooterRenderer from './FooterRenderer/FooterRenderer';

type ResposiveFooterRenderArgs = {
    node: Element;
    Wrapper: FC<{ children: ReactNode }>;
};

export async function render({ node, Wrapper }: ResposiveFooterRenderArgs): Promise<void> {
    hydrateRoot(
        node,
        <Wrapper>
            <FooterRenderer />
        </Wrapper>,
        { identifierPrefix: 'footer' }
    );
}
