import { type FC } from 'react';
import { connect } from 'react-redux';
import { useFragment, graphql } from 'react-relay';
import { GridRow, GridCol } from 'dibs-elements/exports/Grid';
import LinkColumnItem from './LinkColumn/LinkColumnItem';
import { legalLinks, mobileLegalLinks, copyrightLink } from './links';
import RecaptchaTerms from 'dibs-recaptcha/exports/RecaptchaTerms';
import { type FooterLink } from './FooterLinkTypes';
import LogoMonogram from 'dibs-icons/exports/legacy/LogoMonogram';
import { GlobalSitesLinks } from './GlobalSitesLinks/GlobalSitesLinks';
import DisplaySettingsModalLazy from '../DisplaySettingsModal/DisplaySettingsModalLazy';
import { type FooterBottomRow_viewer$key } from './__generated__/FooterBottomRow_viewer.graphql';

import style from './FooterBottomRow.scss';

type ReduxStoreType = {
    header: {
        isMobile: boolean;
        showGlobalSitesLinks: boolean;
    };
};

type Props = {
    filterLinks: (links: FooterLink[]) => FooterLink[];
    isMobile: boolean;
    showGlobalSitesLinks: boolean;
    viewer: FooterBottomRow_viewer$key;
};

const FooterBottomRow: FC<Props> = ({
    filterLinks,
    isMobile,
    showGlobalSitesLinks,
    viewer: viewerRef,
}) => {
    const viewer = useFragment(
        graphql`
            fragment FooterBottomRow_viewer on Viewer {
                user(userId: $userId) @include(if: $hasUserId) {
                    ...DisplaySettingsModal_user
                }
                ...DisplaySettingsModal_viewer
            }
        `,
        viewerRef
    );
    const linksToMap: FooterLink[] = isMobile ? mobileLegalLinks : legalLinks;

    return (
        <div className={style.footerBottomRow}>
            <GridRow align="top">
                <GridCol desktop={6}>
                    <ul className={style.legalLinkWrapper}>
                        {filterLinks(linksToMap).map(link => (
                            <LinkColumnItem key={link.dataTn} {...link} isLegalLink />
                        ))}
                    </ul>
                    {showGlobalSitesLinks && <GlobalSitesLinks />}
                    <RecaptchaTerms
                        wrapperClass={style.recaptchaTermsWrapper}
                        linkType="contrastLinkAlternate"
                        linkClass={style.recaptchaLink}
                    />
                    {isMobile && (
                        <>
                            <ul className={style.copyrightLink}>
                                <LinkColumnItem
                                    key={copyrightLink.dataTn}
                                    {...copyrightLink}
                                    isLegalLink
                                />
                            </ul>
                            <DisplaySettingsModalLazy
                                placement="mobileFooter"
                                viewer={viewer}
                                user={viewer.user || null}
                            />
                        </>
                    )}
                </GridCol>
                {!isMobile && (
                    <GridCol desktop={6}>
                        <div>
                            <DisplaySettingsModalLazy viewer={viewer} user={viewer.user || null} />
                        </div>
                    </GridCol>
                )}
            </GridRow>
            <GridRow align="top">
                <GridCol desktop={12}>
                    <div className={style.monogramIconWrapper}>
                        <div className={style.monogramIcon}>
                            <LogoMonogram />
                        </div>
                    </div>
                </GridCol>
            </GridRow>
        </div>
    );
};

const mapStateToProps = ({
    header,
}: ReduxStoreType): {
    isMobile: boolean;
    showGlobalSitesLinks: boolean;
} => {
    const { isMobile, showGlobalSitesLinks } = header;
    return {
        isMobile,
        showGlobalSitesLinks,
    };
};

export default connect(mapStateToProps)(FooterBottomRow);
