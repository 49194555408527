import { FormattedMessage } from 'dibs-react-intl';
import { type FooterLink } from '../FooterLinkTypes';

const companyLinks: FooterLink[] = [
    {
        trackingLabel: 'About Us',
        href: '/about/',
        dataTn: 'footer-about-us',
        message: <FormattedMessage id="dbl.Footer.site_links_about_us" defaultMessage="About Us" />,
    },
    {
        trackingLabel: 'Press',
        href: '/about/press/',
        dataTn: 'footer-press',
        message: <FormattedMessage id="dbl.Footer.site_links_press" defaultMessage="Press" />,
    },
    {
        trackingLabel: 'Careers',
        href: '/about/careers/',
        dataTn: 'footer-jobs',
        message: <FormattedMessage id="dbl.Footer.site_links_jobs" defaultMessage="Careers" />,
    },
    {
        trackingLabel: 'Investors',
        href: 'https://investors.1stdibs.com/',
        dataTn: 'footer-investors',
        message: (
            <FormattedMessage id="dbl.Footer.site_links_investors" defaultMessage="Investors" />
        ),
    },
    {
        trackingLabel: '1stdibs Reviews',
        href: '/about/1stdibs-reviews/',
        target: '_blank',
        dataTn: 'footer-1stdibs-reviews',
        enablerKey: 'reviewsLink',
        message: (
            <FormattedMessage id="dbl.Footer.site_links_1stdibs_reviews" defaultMessage="Reviews" />
        ),
    },
];

export default companyLinks;
