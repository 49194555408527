import { FormattedMessage } from 'dibs-react-intl';
import { type FooterLink } from '../FooterLinkTypes';

const tradeLinks: FooterLink[] = [
    {
        trackingLabel: 'Trade Program',
        href: '/trade/apply/',
        dataTn: 'footer-designer-program',
        message: (
            <FormattedMessage
                id="dbl.Footer.primary_links_trade_program"
                defaultMessage="Trade Program"
            />
        ),
    },
];

export default tradeLinks;
