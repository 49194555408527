import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type LogoMonogramProps = {
    className?: string;
};

const LogoMonogram: FunctionComponent<LogoMonogramProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            role="img"
            data-tn="logo-monogram"
            viewBox="0 0 39.93302 48.5297"
            aria-label="1stDibs"
        >
            <path d="M15.183 29.464h2.496v8.528c0 2.565 1.248 4.02 3.397 4.02a5.64 5.64 0 005.06-3.396l-.138-.208a5.224 5.224 0 01-3.258 1.456c-1.733 0-2.357-.832-2.357-3.05l-.07-7.35h4.853l.486-2.149h-5.339v-3.189l-5.13 4.992zm4.645-21.491c1.387 0 2.842 1.109 4.229 2.565V7.557a8.766 8.766 0 00-3.952-1.04c-2.496 0-4.506 1.525-4.506 4.02 0 1.803 1.178 2.982 2.496 3.883l2.565 1.734c1.248.831 1.664 1.386 1.664 2.357a1.833 1.833 0 01-2.08 1.802c-1.525 0-2.773-1.109-4.576-3.05v3.258a7.194 7.194 0 004.16 1.387c2.496 0 4.853-1.872 4.853-4.299 0-1.871-1.11-2.98-2.773-4.09l-2.288-1.525c-1.04-.693-1.664-1.317-1.664-2.288a1.751 1.751 0 011.872-1.733m-14.004 6.1v21.978c0 3.743-1.595 4.783-4.853 5.893h13.865c-3.258-1.11-4.853-2.15-4.853-5.893V10.538a38.81 38.81 0 01.278-5.13A22.692 22.692 0 010 9.15v.624c4.992 0 5.824 1.318 5.824 4.299M14.35 0H.416v1.733H13.45c14.559 0 20.59 9.152 20.59 22.185 0 15.044-6.447 22.879-19.273 22.879H.624v1.733h14.282c18.372 0 25.027-12.48 25.027-24.612C39.933 12.202 32.862 0 14.351 0" />
        </svg>
    );
};
export default LogoMonogram;
