import { type FunctionComponent, useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { useDispatch } from 'react-redux';
import SV from 'server-vars';

// components
import { Link } from 'dibs-elements/exports/Link';
import Contact1stdibsModal from '../../contact1stdibs/Contact1stdibsModal';
import {
    getGetHelpZendeskLocalStorageState,
    isGetHelpModalZendeskVariant,
    resetGetHelpZendeskLocalStorageState,
} from 'dibs-contact-1stdibs/exports/getHelpHelpers';

import styles from '../Footer/LinkColumn/LinkColumnItem.scss';

// helpers
import { trackNavigationClick } from '../../helpers/trackingHelpers';
import { actionCreators } from '../../actions';

type Props = {
    trackingLabel: string;
};

const pageType = SV.get('settings.pageType');

const Contact1stdibsFooterLink: FunctionComponent<Props> = ({ trackingLabel }) => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [isPersistedContactModalOpen, setIsPersistedContactModalOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const isPdpPage = pageType === 'pdp';

        if (
            isGetHelpModalZendeskVariant() &&
            !!getGetHelpZendeskLocalStorageState()?.isOpen &&
            !isPdpPage
        ) {
            setIsPersistedContactModalOpen(true);
        }

        if (isPdpPage) {
            resetGetHelpZendeskLocalStorageState();
        }
    }, []);

    const onChatStart = useCallback(
        () => dispatch(actionCreators.setActiveLiveChatVisibility(true)),
        [dispatch]
    );

    const onChatEnd = useCallback(
        () => dispatch(actionCreators.setActiveLiveChatVisibility(false)),
        [dispatch]
    );

    return (
        <li className={styles.item}>
            <Link
                onClick={event => {
                    setIsContactModalOpen(true);
                    trackNavigationClick({ label: trackingLabel }, event);
                }}
                className={styles.contact1stdibsLink}
                dataTn="footer-contact-1stdibs"
            >
                <FormattedMessage
                    id="dbl.Footer.site_links_contact_1stdibs"
                    defaultMessage="Get Help"
                />
            </Link>
            <Contact1stdibsModal
                isOpen={isContactModalOpen || isPersistedContactModalOpen}
                placement="globalFooter"
                onClose={() => {
                    if (isPersistedContactModalOpen) {
                        setIsPersistedContactModalOpen(false);
                    } else {
                        setIsContactModalOpen(false);
                    }
                }}
                onChatStart={onChatStart}
                onChatEnd={onChatEnd}
                isPersistedModal={isPersistedContactModalOpen}
            />
        </li>
    );
};

export default Contact1stdibsFooterLink;
