import { type FooterLink } from '../FooterLinkTypes';

export type SocialMediaType = 'instagram' | 'facebook' | 'pinterest';

type SocialLink = FooterLink & {
    trackingLabel: string;
    name: SocialMediaType;
    href: string;
    icon: string;
    dataTn: string;
};

const socialLink = (name: SocialMediaType, link: string): SocialLink => ({
    trackingLabel: name,
    name: name,
    href: link,
    icon: name,
    dataTn: `social-${name}`,
});

export default [
    socialLink('instagram', 'https://instagram.com/1stdibs/'),
    socialLink('facebook', 'https://www.facebook.com/1stdibs'),
    socialLink('pinterest', 'https://www.pinterest.com/1stdibs/'),
];
