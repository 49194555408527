import { type FC, Suspense, useEffect, useState, lazy } from 'react';

import { type GetHelpModalProps } from '../src/types';
import { getGetHelpZendeskLocalStorageState } from '../src/getHelpZendesk/getHelpZendeskHelpers';
import { isGetHelpModalZendeskVariant } from '../src/helpers';

const GetHelpModal = lazy(() => import(/* webpackChunkName: "GetHelpModal" */ '../src/GetHelp'));

const GetHelpModalLazy: FC<GetHelpModalProps & { isPersistedModal?: boolean }> = ({
    isOpen,
    onClose,
    isPersistedModal,
    ...props
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const isModalAlreadyOpen = isGetHelpModalZendeskVariant()
            ? !!getGetHelpZendeskLocalStorageState()?.isOpen
            : false;

        if (isOpen && (!isModalAlreadyOpen || isPersistedModal)) {
            setIsModalOpen(true);
        } else if (isOpen) {
            onClose();
        }
    }, [isOpen, onClose, isPersistedModal]);

    return isModalOpen ? (
        <Suspense fallback={null}>
            <GetHelpModal
                {...props}
                isOpen
                onClose={() => {
                    onClose();
                    setIsModalOpen(false);
                }}
            />
        </Suspense>
    ) : null;
};

export { GetHelpModalLazy };
