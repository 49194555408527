import { lazy, Suspense, type ComponentProps, type FC } from 'react';
import { useClientState } from 'dibs-react-hooks/exports/useClientState';

const SocialLinkRowItemReactLazy = lazy(
    () =>
        // adblockers don't like "SocialIcons" so rot13 = FbpvnyVpbaf
        import(/* webpackChunkName: "FbpvnyVpbaf" */ './SocialLinkRowItem')
);

const SocialLinkRowItemLazy: FC<ComponentProps<typeof SocialLinkRowItemReactLazy>> = props => {
    const isClient = useClientState();
    if (!isClient) {
        return null;
    }
    return (
        <Suspense fallback={null}>
            <SocialLinkRowItemReactLazy {...props} />
        </Suspense>
    );
};

export default SocialLinkRowItemLazy;
