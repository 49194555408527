import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { type FooterLink } from '../FooterLinkTypes';

import styles from './legalLinks.module.css';

const copyrightLink: FooterLink = {
    trackingLabel: 'Copyright',
    dataTn: 'footer-copyright',
    message: (
        <FormattedMessage
            id="dbl.Footer.legal_links_copyright"
            defaultMessage={`© 1stDibs.com, Inc. {year}`}
            values={{ year: new Date().getFullYear() }}
        />
    ),
};

const cookiePreferencesCopy = (
    <FormattedMessage id="dbl.Footer.cookiePreferences" defaultMessage="Cookie Preferences" />
);

const links: FooterLink[] = [
    {
        trackingLabel: 'User Agreement',
        href: '/about/user-agreement/',
        target: '_blank',
        dataTn: 'footer-agreement',
        message: (
            <FormattedMessage
                id="dbl.Footer.legal_links_agreement"
                defaultMessage="User Agreement"
            />
        ),
    },
    {
        trackingLabel: 'Privacy Policy',
        href: '/about/privacy-policy/',
        target: '_blank',
        dataTn: 'footer-privacy',
        message: (
            <FormattedMessage id="dbl.Footer.legalLinksPrivacy" defaultMessage="Privacy Policy" />
        ),
        badgeText: <FormattedMessage id="dbl.Footer.legalLinksUpdated" defaultMessage="Updated" />,
    },
    {
        trackingLabel: 'Do Not Sell My Info',
        href: 'https://www.1stdibs.com/info/forms2/ccpa-do-not-sell-request/',
        target: '_blank',
        dataTn: 'do-not-sell-main',
        isLinkable: false,
        message: (
            <FormattedMessage
                id="dbl.Footer.do_not_sell_main"
                defaultMessage="Do Not Sell My Info"
            />
        ),
    },
    {
        trackingLabel: 'Cookie Preferences',
        dataTn: 'cookie-settings',
        isLinkable: false,
        message: (
            <Link className={`ot-sdk-show-settings ${styles.cookiePreferences}`} onClick={() => {}}>
                {/** The button must have the ot-sdk-show-settings class to trigger the OneTrust cookie
                 * preferences modal. The text on this button is always overwritten by the OneTrust script.
                 */}
                {cookiePreferencesCopy}
            </Link>
        ),
    },
    {
        trackingLabel: 'Site Map',
        href: '/sitemap/',
        enablerKey: 'sitemapLinks',
        dataTn: 'sitemap-main',
        message: <FormattedMessage id="dbl.Footer.sitemap_links_main" defaultMessage="Site Map" />,
    },
];

const legalLinks: FooterLink[] = [copyrightLink, ...links];

const mobileLegalLinks: FooterLink[] = links.map(link => {
    if (link.trackingLabel === 'Cookie Preferences') {
        return {
            ...link,
            message: (
                <Link className="ot-sdk-show-settings" onClick={() => {}}>
                    {/** The button must have the ot-sdk-show-settings class to trigger the OneTrust cookie
                     * preferences modal. The text on this button is always overwritten by the OneTrust script.
                     */}
                    {cookiePreferencesCopy}
                </Link>
            ),
        };
    }

    return link;
});

export { mobileLegalLinks, copyrightLink };

export default legalLinks;
