import { type FC, useEffect, useTransition, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRefetchableFragment, graphql } from 'react-relay';
import { getBuyerId } from 'dibs-cookie-jar';

import { GridRow, GridCol } from 'dibs-elements/exports/Grid';

import LinkColumn from './LinkColumn/LinkColumn';
import LinkColumnItem from './LinkColumn/LinkColumnItem';
import FooterBottomRow from './FooterBottomRow';
import { type FooterLink } from './FooterLinkTypes';
import { linkColumnConfig, filterLinks } from '../footerHelpers';
import { isSellerBrandingRemovalTestVariant } from '../../utils/abTest/sellerBrandingRemovalAbTestHelpers';

import style from './Footer-style.scss';

import {
    type Footer_viewer$data,
    type Footer_viewer$key,
} from './__generated__/Footer_viewer.graphql';

export type enablerKeys = keyof Footer_viewer$data;

type RenderListItem = {
    linkConfig: FooterLink;
    viewer: Footer_viewer$data;
};

const RenderListItem: FC<RenderListItem> = ({ linkConfig, viewer }) => {
    const { CustomComponent, ...item } = linkConfig;
    return CustomComponent ? (
        <CustomComponent key={item.dataTn} {...item} viewer={viewer} />
    ) : (
        <LinkColumnItem key={item.dataTn} {...item} />
    );
};

export type ReduxStoreType = {
    header: {
        hideFooter: boolean;
        updateUserState: boolean;
    };
};

type Props = {
    viewer: Footer_viewer$key;
};

const Footer: FC<Props> = ({ viewer: viewerRef }) => {
    const [viewer, refetch] = useRefetchableFragment(
        graphql`
            fragment Footer_viewer on Viewer @refetchable(queryName: "FooterRefetchQuery") {
                footerNftLink: isEnabledByLocale(featureName: "NFT_LINK")
                footerQuestionsAnswersLink: isEnabledByLocale(featureName: "QUESTIONS_LINK")
                reviewsLink: isEnabledByLocale(featureName: "REVIEWS_LINK")
                sitemapLinks: isEnabledByLocale(featureName: "SITEMAP")
                wpEditorialLink: isEnabledByLocale(featureName: "WP_EDITORIAL_LINK")
                ...FooterSubscribe_viewer
                ...FooterBottomRow_viewer
            }
        `,
        viewerRef
    );
    const { hideFooter, updateUserState } = useSelector((state: ReduxStoreType) => state.header);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_isPending, startTransition] = useTransition();

    const [isSellerBrandRemovalVariant, setIsSellerBrandRemovalVariant] = useState<boolean>(false);

    useEffect(() => {
        const userId = getBuyerId(document.cookie) || '';
        const hasUserId = !!userId;
        startTransition(() => {
            refetch({ userId, hasUserId });
        });
    }, [updateUserState, refetch, startTransition]);

    useEffect(() => {
        setIsSellerBrandRemovalVariant(isSellerBrandingRemovalTestVariant());
    }, []);

    return (
        <footer className={style.footer} hidden={hideFooter}>
            <>
                <div className={style.footerRow}>
                    <GridRow justify="center">
                        {linkColumnConfig.map(
                            (
                                {
                                    enablerKey,
                                    disablerKey,
                                    columnWidth,
                                    title,
                                    items,
                                    additionalLink,
                                },
                                index
                            ) => {
                                let showLinkColumn = true;
                                if (enablerKey) {
                                    showLinkColumn = viewer[enablerKey as enablerKeys] === true;
                                }
                                if (disablerKey) {
                                    showLinkColumn = viewer[disablerKey as enablerKeys] === false;
                                }
                                return (
                                    showLinkColumn && (
                                        <GridCol key={`link-column-${index}`} desktop={columnWidth}>
                                            <LinkColumn title={title}>
                                                {filterLinks(
                                                    items,
                                                    viewer,
                                                    isSellerBrandRemovalVariant
                                                ).map((item, i) => (
                                                    <RenderListItem
                                                        key={i}
                                                        linkConfig={item}
                                                        viewer={viewer}
                                                    />
                                                ))}
                                            </LinkColumn>
                                            {additionalLink && (
                                                <div className={style.additionalLinkContainer}>
                                                    <LinkColumn title={additionalLink.title}>
                                                        {filterLinks(
                                                            additionalLink.items,
                                                            viewer,
                                                            isSellerBrandRemovalVariant
                                                        ).map((item, i) => (
                                                            <RenderListItem
                                                                key={i}
                                                                linkConfig={item}
                                                                viewer={viewer}
                                                            />
                                                        ))}
                                                    </LinkColumn>
                                                </div>
                                            )}
                                        </GridCol>
                                    )
                                );
                            }
                        )}
                    </GridRow>
                </div>
                <FooterBottomRow
                    viewer={viewer}
                    filterLinks={(links: FooterLink[]) =>
                        filterLinks(links, viewer, isSellerBrandRemovalVariant)
                    }
                />
            </>
        </footer>
    );
};

export default Footer;
